import { Injectable } from '@angular/core';
import { map, Observable, of, Subject, takeUntil, timeout, timer } from 'rxjs';
import { ApiMethod, Endpoints, SCREENMODE } from '../utils/constants';
import { HttpService } from '../http/http.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

const mockJson = {
  status: 'success',
  code: 200,
  message: '',
  data: {
    total_records: 151,
    records: [],
    additional_data: {
      coding_request: {
        status_count: [
          {
            id: 1,
            display_name: 'Forecast',
            text_color_code: '#000000',
            color_code: '#FFA500',
            total_count: 30,
          },
          {
            id: 2,
            display_name: 'Order',
            text_color_code: '#000000',
            color_code: '#A97C12',
            total_count: 16,
          },
        ],
        total_count: 46,
      },
      codes_generated: {
        status_count: [
          {
            id: 6,
            display_name: 'Forecast',
            text_color_code: '#000000',
            color_code: '#FFA500',
            total_count: 500,
          },
          {
            id: 8,
            display_name: 'Order',
            text_color_code: '#000000',
            color_code: '#A97C12',
            total_count: 200,
          },
        ],
        total_count: 700,
      },
    },
  },
  meta: {
    version: '1.0',
    timestamp: '2025-02-19T15:44:45.619185',
  },
};

const next_status = {
  status: 'success',
  code: 200,
  message: '',
  data: {
    next_states: [
      {
        id: 7,
        slug: 'onboard_approve',
        label: 'Approve',
        action_complete_label: 'Approved',
        content_type: 55,
        color_code: '#228B22',
        icon: 'approvereq',
        text_color_code: '#FFFFFF',
        message:
          'Do you want to approve the onboard request? Please Note that this action is permanent and cannot be reversed',
      },
    ],
  },
  meta: {
    version: '1.0',
    timestamp: '2025-02-21T22:17:21.898186',
  },
};

@Injectable({
  providedIn: 'root',
})
export class CodingService {
  staticText: any = TranslationService.staticTextData;
  private destroy$ = new Subject<void>();

  uidCodeGenerateProgressSubject = new Subject<any>();

  codingMangementTab: any = [
    {
      id: 1,
      display_name: 'Forecast',
      total_count: '12',
    },
    {
      id: 2,
      display_name: 'Order',
      total_count: '32',
    },
  ];

  constructor(private _http: HttpService) {}

  getCodingList(param: any): Observable<any> {
    return of(mockJson).pipe(timeout(2000));
    /*let endpoint: any = '';
    if (param) endpoint = `${Endpoints.FORECAST_TAX_STAMP}${param}`;
    else endpoint = `${Endpoints.FORECAST_TAX_STAMP}`;
    return this._http.requestCall(endpoint, ApiMethod.GET).pipe(
      map((e: any) => e),
      takeUntil(this.destroy$)
    );*/
  }
  getCodingById(param: any): Observable<any> {
    return timer(3000).pipe(map(() => ({ data: {} })));
    /*let endpoint: any = '';
    if (param) endpoint = `${Endpoints.FORECAST_TAX_STAMP}${param}`;
    else endpoint = `${Endpoints.FORECAST_TAX_STAMP}`;
    return this._http.requestCall(endpoint, ApiMethod.GET).pipe(
      map((e: any) => e),
      takeUntil(this.destroy$)
    );*/
  }
  getOrderProductDetailsByItem(id: any): Observable<any> {
    let endpoint: any = `${Endpoints.GET_ORDER_PRODUCT_CODE_DETAILS}${id}`;
    return this._http.requestCall(endpoint, ApiMethod.GET).pipe(
      map((e: any) => e),
      takeUntil(this.destroy$)
    );
  }
  getNextStatus(id: any, module: any): Observable<any> {
    let endpoint: any =
      module == SCREENMODE.FORECAST
        ? `${Endpoints.GET_NEXT_FORECAST_CODE_STATUS}${id}`
        : `${Endpoints.GET_NEXT_ORDER_PRODUCT_CODE_STATUS}${id}`;
    return this._http.requestCall(endpoint, ApiMethod.GET).pipe(
      map((e: any) => e),
      takeUntil(this.destroy$)
    );
  }

  initiateCodeGeneration(
    id: any,
    formData: any,
    options: any,
    module: any
  ): Observable<any> {
    const header = {
      observe: 'response',
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    let endpoint: any =
      module == SCREENMODE.FORECAST
        ? `${Endpoints.INITIATE_FORECAST_CODE_GENERATION}${id}/${options}`
        : `${Endpoints.INITIATE_ORDER_PRODUCT_CODE_GENERATION}${id}/${options}`;
    return this._http
      .requestCall(endpoint, ApiMethod.POST, formData, header)
      .pipe(
        map((e: any) => e),
        takeUntil(this.destroy$)
      );
  }
}
